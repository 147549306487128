<script setup>
import { ref, watch, computed, onMounted, nextTick } from "vue";
import { useRoute } from "vue-router";
import useVisibleIntersection from "../../../composables/useVisibleIntersection";
import { TransitionPresets, useTransition } from "@vueuse/core";
import CommonKeyCounterNumber from "../../common/key-counter-number/key-counter-number.vue";
import useTheme from "../../../composables/useTheme";
import { isSafari } from "~/utils";

const props = defineProps({
  number: [String, Number],
  heading: [String, null],
  theme: [Object, null],
  suffix: {
    type: String,
    default: null,
  },
  speed: {
    type: Number,
    default: 500, // factor based on number of digits in milliseconds
  },
  animated: {
    type: Boolean,
    default: true,
  },
  screenTriggered: {
    type: Boolean,
    default: true,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});

const { toCSS } = useTheme();

const element = ref(null);

const isVisible = useVisibleIntersection(element);

const fullSpeed = computed(() => {
  return String(props.number).length * props.speed;
});

const duration = computed(() => {
  return isVisible.value ? isSafari() ? fullSpeed.value * 1  : fullSpeed.value : 0;
});
const baseDistance = computed(() => {
  let n = Number(String(props.number).split(".")[0]);
  return isVisible.value ? n : n * 0.2;
});

const counter = useTransition(baseDistance, {
  duration,
  transition: TransitionPresets.linear,
});

const counterFullDigits = (num, counter) => {
  let n = String(num).split(".")[0];
  let digits = String(n).split("").length;
  return String(Math.ceil(counter)).padStart(digits, "0");
};

const count = computed(() => {
  return counterFullDigits(props.number, counter.value);
});

const placeholder = computed(() => {
  let n = String(props.number).split(".")[0];
  let ceros = "";
  if (count) {
    for (var i = 0; i < n.length; i++) {
      ceros += "0";
    }
  }
  return ceros;
});

const decimals = computed(() => {
  let n = String(props.number).split(".");
  if (n.length >= 2) {
    return "," + n[1];
  } else {
    return null;
  }
});

const numberWrapper = ref(null);
const headingWrapper = ref(null);
const { width: numberWidth } = useElementBounding(numberWrapper);
const { width: headingWidth } = useElementBounding(headingWrapper);
const { width } = useElementSize(element);

const ready = ref(false);
const scaleFactor = ref(null);
const timeout = ref(null);

const onResize = function () {
  ready.value = false;
  scaleFactor.value = 1;
  if (timeout.value) {
    clearTimeout(timeout.value);
  }
  timeout.value = setTimeout(() => {
    let factor = (1 / numberWidth.value) * headingWidth.value;
    scaleFactor.value = factor < 1 ? factor : 1;
    ready.value = true;
  }, 100);
};

const maxWidth = computed(() => {
  return headingWidth.value;
});

const backgroundColor = ref(null);
const r = ref(0);
const g = ref(0);
const b = ref(0);

const toRGB = function (str) {
  let sep = str.indexOf(",") > -1 ? "," : " ";
  return str.substr(4).split(")")[0].split(sep).map(Number);
};

const getBackgroundColor = function () {
  const color = window
    .getComputedStyle(element.value, null)
    .getPropertyValue("background-color");

  if (color) {
    backgroundColor.value = color;
    const rgb = toRGB(color);
    r.value = rgb[0];
    g.value = rgb[1];
    b.value = rgb[2];
    /*
    r.value = 255; //rgb[0];
    g.value = 0; //rgb[1];
    b.value = 0; //rgb[2];
     */
  } else {
    setTimeout(() => {
      getBackgroundColor();
    }, 10);
  }
};

onMounted(async () => {
  if (element) {
    await nextTick();
    getBackgroundColor();
    timeout.value = setTimeout(() => {
      onResize();
    }, 500);
    if (process.client) {
      window.addEventListener("resize", onResize);
    }
  }
});

onUnmounted(() => {
  if (process.client) {
    window.removeEventListener("resize", onResize);
  }
});
</script>

<template>
  <div
    class="key-number-item"
    ref="element"
    :class="[
      (isVisible && screenTriggered) || (visible && !screenTriggered)
        ? 'visible'
        : 'hidden',
      animated ? 'animated' : 'static',
    ]"
    :style="{
      ...toCSS(theme),
    }"
  >
    <!--
    <code>
      maxWidth: {{ maxWidth }}<br />
      {{ headingWidth + numberWidth }}<br />
      numberWidth: {{ numberWidth }}<br />
      headingWidth: {{ headingWidth }}<br />
      scaleFactor: {{ scaleFactor }}<br />
    </code>
    <br /><br /><br />
    -->

    <div class="key-number-item__inner" ref="inner">
      <div class="key-number-item__inner__line"></div>

      <span class="key-number-item__inner__number" v-if="count"
        >{{ placeholder }}{{ decimals }}
        <div class="key-number-item__inner__suffix">{{ suffix }}</div></span
      >

      <div
        class="key-number-item__wrapper"
        v-if="count > 0"
        ref="numberWrapper"
        :class="[ready ? 'visible' : 'hidden']"
        :style="{
          transform: `scale(${scaleFactor})`,
        }"
      >
        <common-key-counter-number
          v-for="(num, index) in count.length"
          :key="index"
          v-bind="{
            index: index,
            number: count.split('')[index],
            speed: fullSpeed / number,
          }"
        />

        <div
          class="key-number-item__wrapper__gradient"
          :style="{
            background: `linear-gradient( 0deg, rgba(${r}, ${g}, ${b}, 1) 0%, rgba(${r}, ${g}, ${b}, 0) 10%, rgba(${r}, ${g}, ${b}, 0) 90%, rgba(${r}, ${g}, ${b}, 1) 100%)`,
          }"
        />

        <common-key-counter-number
          v-if="decimals"
          v-bind="{
            number: null,
            decimals: decimals,
            speed: 0,
            suffix: null,
          }"
        />
        <common-key-counter-number
          v-if="suffix"
          v-bind="{
            number: null,
            speed: 0,
            suffix: suffix,
          }"
        />
      </div>

      <h4
        class="key-number-item__inner__heading ts-subhead-1"
        ref="headingWrapper"
      >
        <span v-html="heading" v-if="heading"></span>
      </h4>
    </div>
  </div>
</template>

<style lang="postcss">
.key-number-item {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: hsl(var(--theme-bg));


  &.animated {
    &.visible {
      .key-number-item__inner {
        .key-number-item__inner__line {
          width: 100%;
          transition: width 0.5s ease;
        }
      }
      .key-number-item__inner__heading {
        opacity: 1;
        transition: opacity 0.5s ease 0.25s;
      }
    }

    &.hidden {
      .key-number-item__inner {
        .key-number-item__inner__line {
          width: 0;
          transition-delay: 0s !important;
        }
      }
      .key-number-item__inner__heading {
        opacity: 0;
      }
    }
  }

  &__wrapper {
    position: absolute;
    top: 10px;
    left: 0;
    overflow: hidden;
    transform-origin: top left;
    /* color: red; */

    &.visible {
      opacity: 1;
      transition: opacity 0.25s ease;
    }

    &.hidden {
      opacity: 0;
      transition: none;
    }

    @media (--lg) {
      top: -8px;
    }

    &__gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 101%;
    }
  }

  &__inner {
    position: relative;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: var(--grid-gap);

    &.column {
      flex-direction: column;
      padding-bottom: units(2);
    }
    padding: units(2) 0 0;

    .key-number-item__inner__line {
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: currentColor;
      opacity: 0.3;
      z-index: 1;
    }

    &__heading {
      opacity: 1;
      grid-column-start: 4;
      grid-column-end: 7;
    }

    &__suffix {
      font-size: units(2.5);
      margin-top: units(4);
      @media (--lg) {
        margin-top: units(7.6);
        font-size: units(10);
      }
    }

    &__number {
      display: flex;
      flex: 1;
      /* line-height: 1; */
      font-family: var(--grotzec);
      font-size: units(8);
      /* color: red; */
      visibility: hidden;
      grid-column-start: 1;
      grid-column-end: 4;

      @media (--lg) {
        font-size: units(16.5);
      }
    }
  }
}
</style>
